import React, { useCallback, useEffect, useState } from "react";
import CustomDatePicker from "../components/datePicker/DatePicker";
import TimeSlots from "../TimeSlots";
import { ThreeDots } from "react-loader-spinner";
import Header from "../components/header/Header90Days";
import Footer from "../components/footer/Footer90Days";
import Select from "react-select";
import ConfirmationDetails from "../components/confirmation/ConfirmationDetails";
import { CiLocationOn } from "react-icons/ci";
import { stateOptions } from "../constants/statesLis";
import Confirmation from "../components/confirmation";
import { useSearchParams } from "react-router-dom";
import { usePaymentInputs } from "react-payment-inputs";
import Input from "../components/Input";
import { url } from "../constants/url";
import {
  filterNonOverlappingSlots,
  getUniqueAppointmentDates,
  groupByStartTime,
  validateAndFormatExpiryDate
} from "../utils/utils";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";

const Appointment = () => {
  let [searchParams] = useSearchParams();

  let first_name = searchParams.get("first_name");

  let last_name = searchParams.get("last_name");

  let phone = searchParams.get("phone");

  let email = searchParams.get("email");

  let state = searchParams.get("state");

  const [selectedDate, setSelectedDate] = useState("");
  const [alreadyConfirmedList, setAlreadyConfirmedList] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedState, setSelectedState] = useState(state);
  const [steps, setSteps] = useState(1);
  const [currentTime, setCurrentTime] = useState("");
  const [data, setData] = useState([]);
  const [UID, setUID] = useState();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dates, setDates] = useState();
  const [noSlot, setNoSlot] = useState(false);
  const [address, setAddress] = useState();
  const [groupedData, setGroupedData] = useState([]);
  const [slotError, setSlotError] = useState(false);
  const [appointmentID, setAppointmentID] = useState();
  const [form, setforms] = useState(false);

  const [csv, setCSV] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [errMSG, setERRMSG] = useState("");
  const [srvrErrMSG, setSrvrErrMSG] = useState("");
  const [loadingText, setLoadingText] = useState(
    "Finding Available Slots and a licensed practitioner in your state"
  );

  useEffect(() => {
    const timer =
      selectedState &&
      setInterval(() => {
        const newDate = new Date();
        const timeFormatter = new Intl.DateTimeFormat("en-US", {
          timeZone: selectedState.timeZone,
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true
        });
        setCurrentTime(timeFormatter.format(newDate));
      }, 1000);
    return () => timer && clearInterval(timer);
  }, [selectedState]);

  useEffect(() => {
    localStorage.setItem("name", `${first_name} ${last_name}`);
    return () => localStorage.removeItem("name");
  }, [last_name, first_name]);

  const fetchData = useCallback(
    async (date = null) => {
      setLoading(true);
      setError(null);
      try {
        const requestBody = {
          visit_type: 74,
          first_name,
          last_name,
          phone_number: phone,
          email,

          state: selectedState?.value
        };

        if (date) {
          requestBody.date = date;
        }

        const response = await fetch(`${url}api/v1/prognocis/get_free_slots/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          throw new Error("Oops! Something went wrong, Please contact support");
        }

        const result = await response.json();
        setData(result);
        setSteps(result?.slots?.length > 0 ? 2 : steps);
        setNoSlot(result?.slots?.length === 0);
      } catch (error) {
        console.error("fetchData error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [first_name, last_name, phone, email, selectedState, steps, url]
  );

  useEffect(() => {
    if (data?.slots) {
      const filteredSlots = filterNonOverlappingSlots(
        data?.slots,
        selectedState.timeZone
      );
      setAvailableSlots([...availableSlots, ...filteredSlots]);
      setDates(
        getUniqueAppointmentDates(
          [...availableSlots, ...filteredSlots],
          selectedState.timeZone
        )
      );
      let group = [];
      group = groupByStartTime(data?.slots);
      setGroupedData((prevGroupedData) => ({
        ...prevGroupedData,
        ...group
      }));
      setUID(data?.UID);
    }
  }, [data]);

  const handleConfirm = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let timer = "";
    if (selectedState) {
      timer = setInterval(() => {
        const newDate = new Date();
        const timeFormatter = new Intl.DateTimeFormat("en-US", {
          timeZone: selectedState.timeZone,
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true
        });
        setCurrentTime(timeFormatter.format(newDate));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [selectedState]);

  useEffect(() => {
    localStorage.removeItem("name");
    localStorage.setItem("name", first_name + " " + last_name);
    return () => localStorage.removeItem("name");
  }, [last_name, first_name]);

  useEffect(() => {
    setAddress(selectedState?.timeZone + " " + currentTime);
  }, [selectedState, currentTime]);

  const handleConfirmBooking = async () => {
    if (selectedTime?.start_time) {
      setLoading(true);
      setSlotError(false);
      setLoadingText("Booking your appointment");
      let group = groupedData[selectedTime?.start_time];
      let list = group.map((item) => item.provider_id);
      const body = {
        visit_type: 74,
        reason: "",
        location_id: 3,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone_number: phone,
        provider_id: list,
        patient_id: UID,
        start_time: selectedTime?.start_time
      };

      setSlotError(false);

      try {
        const response = await fetch(
          `${url}api/v1/prognocis/set_appointment/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },

            body: JSON.stringify(body)
          }
        );
        if (!response.ok) {
          if (response.status === 403) {
            setAlreadyConfirmedList([
              ...alreadyConfirmedList,
              { ...selectedTime }
            ]);
            setLoading(false);
            setERRMSG(
              "This Appointment is already Booked Please Select another Slot"
            );
            setSlotError(true);
          }
        }
        const result = await response.json();
        const parsedResult = JSON.parse(result);
        if (parsedResult?.appointment_id) {
          setAppointmentID(parsedResult?.appointment_id);
          setData(parsedResult);
          setLoading(false);

          setSteps(5);
        }
      } catch (error) {
        setError(error.msg);
      } finally {
        setLoading(false);
      }
    } else {
      setERRMSG("Please Select a slot to proceed");
      setSlotError(true);
    }
  };

  const callSetAppointmentEndpoint = async () => {
    const additionalData = {
      appointment_id: appointmentID,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone,
      start_time: selectedTime?.start_time,
      patient_id: UID
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${url}api/v1/prognocis/update_appointment/`,
        additionalData,
        {
          headers: { "Content-Type": "application/json" }
        }
      );
    } catch (error) {
      if (error.response) {
        setLoading(false);
      }
    }
  };
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [steps]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateAndFormatExpiryDate(expiryDate)) {
      setLoadingText("Processing Payment");

      const [month, year] = expiryDate.split("/").map(Number);
      let formatedDate = `${month.toString().padStart(2, "0")}-20${year}`;
      setError(null);
      try {
        const response = await fetch(`${url}api/v1/payment/process_payment/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },

          body: JSON.stringify({
            cardNumber: cardNumber.replace(/\s+/g, ""),
            expirationDate: formatedDate,
            appointment_id: appointmentID,
            cardCode: csv,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone_number: phone,
            product_id: 1
          })
        });
        setLoading(false);

        if (!response.ok) {
          setSrvrErrMSG("Payment Was declined Please check details");
          setLoading(false);
        } else {
          await callSetAppointmentEndpoint();
          setSrvrErrMSG("");
          setLoadingText("");
        }
        if (response.ok) {
          setSteps(6);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
      setERRMSG("");
    } else {
      setLoading(false);
      setERRMSG("Please check and enter a valid expiry date.");
    }
  };

  const handleChange = (e) => {
    setCSV(e.target.value);
  };

  useEffect(() => {
    let initialStateValue = searchParams.get("state");
    if (initialStateValue) {
      const foundState = stateOptions.find(
        (opt) => opt.value === initialStateValue
      );
      if (foundState) {
        setSelectedState(foundState);
      }
    }
  }, [searchParams]);

  const handleBack = () => {
    if (steps !== 5) {
      setSelectedDate();
      setAvailableSlots([]);
      setDates();
      setGroupedData([]);
    }
    setSlotError("");
    setERRMSG("");
    setSelectedTime({});
    setSteps((prev) => (prev === 5 ? prev - 3 : prev - 1));
  };
  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handleCardExpiryChange = (e) => {
    setExpiryDate(e.target.value);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        setSteps={setSteps}
        userName={first_name + " " + last_name}
        userImage=""
        header={steps === 5 ? true : false}
      />
      <div
        title=" 90 Day Follow Up"
        className="md:hidden py-10 block text-xl bg-gray-100 sm:text-2xl md:text-4xl text-center text-gray-800 font-bold  "
      >
        90 Day Follow Up
      </div>
      {steps === 4 && (
        <Confirmation setSteps={setSteps} setShowConfirmation={setforms} />
      )}

      {steps === 1 ? (
        <>
          <div className="flex-grow flex items-center  justify-center md:bg-gray-100">
            <div className="md:max-w-4xl  md:mx-auto md:w-[80%]   p-4 md:p-8 bg-white md:shadow-2xl rounded-lg flex flex-col md:flex-row justify-around md:border border-gray-200 md:space-x-4">
              <div className="flex-1 flex flex-col md:py-10 items-center justify-center space-y-4 p-4">
                <div className="space-y-4">
                  <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-800">
                    Schedule your Appointment!
                  </h1>
                  <h3 className="text-xl md:text-2xl font-bold text-center text-gray-800">
                    What state do you live in?
                  </h3>
                  <h4 className="text-sm md:text-md italic text-center text-gray-500">
                    This helps us connect you with a licensed provider in your
                    state.
                  </h4>
                </div>
                <div className="w-full px-4 md:w-96">
                  <Select
                    options={stateOptions}
                    onChange={handleStateChange}
                    value={selectedState}
                    isClearable
                    placeholder="Search and select a state..."
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: 40,
                        borderColor: "rgba(0, 123, 255, 0.2)",
                        boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          borderColor: "rgba(0, 123, 255, 0.3)"
                        }
                      }),
                      option: (provided) => ({
                        ...provided,
                        height: "30px",
                        display: "flex",
                        alignItems: "center"
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "120px",
                        overflowY: "auto"
                      })
                    }}
                  />
                </div>
                <div className="w-full px-4 md:w-96">
                  <button
                    onClick={handleConfirm}
                    className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-grow flex-col  flex items-center py-20 justify-center bg-gray-100">
          <div className="md:max-w-4xl md:mx-auto bg-white  md:shadow-2xl rounded-lg md:border border-gray-200 p-4 md:p-8 mt-4">
            {steps > 1 && steps <= 5 && (
              <div className="flex justify-start w-full">
                <button
                  onClick={handleBack}
                  className="bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out flex items-center"
                >
                  <IoIosArrowBack className="mr-2" />
                  Back
                </button>
              </div>
            )}
            {steps === 2 && (
              <>
                <div className="text-center text-2xl font-medium  mt-10 sm:mt-0 flex items-center justify-center space-x-2">
                  Select a time to meet with a licensed provider
                </div>
                <div className="grid lg:grid-cols-2">
                  <div className="flex flex-col items-center justify-center pb-14">
                    <CustomDatePicker
                      fetchData={fetchData}
                      timeZone={selectedState?.timeZone}
                      dates={dates}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </div>
                  <div className="flex flex-col space-y-4 px-4 md:py-20">
                    <TimeSlots
                      alreadyConfirmedList={alreadyConfirmedList}
                      timeZone={selectedState?.timeZone}
                      slots={availableSlots}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      setSelectedDate={setSelectedDate}
                      setSelectedTime={setSelectedTime}
                      setSlotError={setSlotError}
                      slotError={slotError}
                      setERRMSG={setERRMSG}
                      errMSG={errMSG}
                    />
                    <button
                      onClick={handleConfirmBooking}
                      className="w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 rounded-3xl shadow-lg"
                      disabled={isLoading}
                    >
                      Confirm Booking
                    </button>
                  </div>
                </div>
                <div className="text-center text-lg font-medium py-4 px-20 flex items-center justify-center space-x-2">
                  <span className="hidden md:block">
                    <CiLocationOn />
                  </span>
                  <span>{address}</span>
                </div>
              </>
            )}

            {steps === 6 && (
              <ConfirmationDetails
                selectedTime={selectedTime}
                setSteps={setSteps}
                setform={setforms}
                user={{ email: "email@example.com", phone: "+1234567890" }}
              />
            )}

            {steps === 5 && (
              <div className="flex flex-col items-center justify-center p-4">
                <form
                  onSubmit={handleSubmit}
                  className="md:max-w-4xl md:mx-auto md:w-[80%] p-4 md:p-8 bg-white rounded-lg border border-gray-200 md:space-x-4"
                >
                  <h2 className="text-xl md:text-2xl font-semibold text-center mb-6 md:mb-8">
                    Add Payment Details
                  </h2>

                  <div className="md:mb-4">
                    <div>
                      <Input
                        {...getCardNumberProps({
                          onChange: handleCardNumberChange
                        })}
                        id="cardNumber"
                        required
                        label="Card number"
                        value={form.cardNumber}
                        className="w-full p-2 rounded-lg border border-gray-300"
                      />
                    </div>
                    <div className="flex flex-wrap w-full">
                      <div className="w-full md:w-1/2 md:pr-2 mb-2 md:mb-0">
                        <Input
                          {...getExpiryDateProps({
                            onChange: handleCardExpiryChange
                          })}
                          id="cardExpDate"
                          required
                          label="Exp. Date"
                          value={form.cardExpIndate}
                          className="w-full p-2 rounded-lg border border-gray-300"
                        />
                      </div>
                      <div className="w-full md:w-1/2 md:pl-2">
                        <Input
                          {...getCVCProps({ onChange: handleChange })}
                          id="cardCVC"
                          required
                          label="Security Code"
                          value={form.cardCVC}
                          className="w-full p-2 rounded-lg border border-gray-300"
                        />
                      </div>
                      {errMSG && (
                        <div className="w-full mt-2">
                          <span className="text-center text-red-600 px-2">
                            {errMSG}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {srvrErrMSG && (
                    <div className="flex justify-center">
                      <span className="text-center text-red-600 px-2">
                        {srvrErrMSG}
                      </span>
                    </div>
                  )}

                  <button
                    onClick={() => setSteps(5)}
                    className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl transition-colors duration-300 ease-in-out"
                  >
                    Pay $25
                  </button>
                  <div className="text-end text-sm text-gray-600 mt-4">
                    Secured by{" "}
                    <a
                      href="https://www.authorize.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#00c19c] hover:text-[#008a73]"
                    >
                      authorize.net
                    </a>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}

      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700">
          <style>
            {`
          @keyframes bubble {
              0%, 100% {
                  transform: translateY(0);
              }
              50% {
                  transform: translateY(-20px);
              }
          }
          .bubbling-text span {
              display: inline-block;
              animation: bubble 1.5s ease-in-out infinite;
          }

        `}
          </style>
          <div className="p-6 bg-white bg-opacity-100 rounded-lg w-1/2 flex flex-col items-center justify-center">
            <p className="text-[#008a73] mt-3 text-center text-xl bubbling-text">
              {loadingText}
            </p>
            <ThreeDots color="#008a73" height={80} width={80} />
          </div>
        </div>
      )}

      {error && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4 text-red-600">Error</h2>
            <p className="text-lg mb-4 text-gray-700">{error}</p>
            <button
              onClick={() => setError(null)}
              className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl transition-colors duration-300 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {noSlot && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-700">
              No Slots Found Please Select another date
            </h2>
            <button
              onClick={() => setNoSlot(null)}
              className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl transition-colors duration-300 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Appointment;
