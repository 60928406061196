import React, { useEffect } from "react";

const NotFound = () => {
  let isScheduled = localStorage.getItem("isScheduled") || "0";
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#00c19c] hover:bg-[#008a73] transition duration-300">
      <div className="max-w-4xl w-full sm:p-8 bg-white shadow-2xl rounded-lg flex flex-col sm:space-x-4 border border-gray-200">
        <div className="flex flex-col justify-center items-center p-8">
          <h1 className="text-6xl font-bold text-[#00c19c] mb-4">
            Link Expired!
          </h1>
          <p className="text-2xl md:text-3xl text-gray-700 mb-8">
            please contact support
          </p>
          <div className="mt-6 w-full max-w-md text-center">
            <h3 className="text-xl font-semibold mb-2">
              {isScheduled === 1 || isScheduled === "1"
                ? "Need help scheduling?"
                : "How can we help you? Our team is standing by to assist you with any questions:"}{" "}
            </h3>
            <p className="text-gray-800 mb-1">
              <strong>Email:</strong>{" "}
              <a
                className="contact-link"
                href={
                  isScheduled === 1 || isScheduled === "1"
                    ? "mailto:hello@signup.liferxmd.net"
                    : "mailto:info@liferxtelemd.com"
                }
                title="Send an email"
              >
                {isScheduled === 1 || isScheduled === "1"
                  ? "hello@signup.liferxmd.net"
                  : "info@liferxtelemd.com"}
              </a>
            </p>
            <p className="text-gray-800">
              <strong>Phone:</strong>{" "}
              <a
                className="contact-link"
                href={
                  isScheduled === 1 || isScheduled === "1"
                    ? "tel:+18333301873"
                    : "tel:+18562120045"
                }
                title="Click to call"
              >
                {isScheduled === 1 || isScheduled === "1"
                  ? "833-330-1873"
                  : " 856-212-0045"}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
