export const stateOptions = [
  { id: 1, value: "Alabama", label: "Alabama", timeZone: "America/Chicago" },
  { id: 2, value: "Alaska", label: "Alaska", timeZone: "America/Anchorage" },
  { id: 3, value: "Arizona", label: "Arizona", timeZone: "America/Phoenix" },
  { id: 4, value: "Arkansas", label: "Arkansas", timeZone: "America/Chicago" },
  { id: 5, value: "California", label: "California", timeZone: "America/Los_Angeles" },
  { id: 6, value: "Colorado", label: "Colorado", timeZone: "America/Denver" },
  { id: 7, value: "Connecticut", label: "Connecticut", timeZone: "America/New_York" },
  { id: 8, value: "Delaware", label: "Delaware", timeZone: "America/New_York" },
  { id: 9, value: "District of Columbia", label: "District of Columbia", timeZone: "America/New_York" },
  { id: 10, value: "Florida", label: "Florida", timeZone: "America/New_York" },
  { id: 11, value: "Georgia", label: "Georgia", timeZone: "America/New_York" },
  { id: 12, value: "Hawaii", label: "Hawaii", timeZone: "Pacific/Honolulu" },
  { id: 13, value: "Idaho", label: "Idaho", timeZone: "America/Boise" },
  { id: 14, value: "Illinois", label: "Illinois", timeZone: "America/Chicago" },
  { id: 15, value: "Indiana", label: "Indiana", timeZone: "America/Indiana/Indianapolis" },
  { id: 16, value: "Iowa", label: "Iowa", timeZone: "America/Chicago" },
  { id: 17, value: "Kansas", label: "Kansas", timeZone: "America/Chicago" },
  { id: 18, value: "Kentucky", label: "Kentucky", timeZone: "America/New_York" },
  { id: 19, value: "Louisiana", label: "Louisiana", timeZone: "America/Chicago" },
  { id: 20, value: "Maine", label: "Maine", timeZone: "America/New_York" },
  { id: 21, value: "Maryland", label: "Maryland", timeZone: "America/New_York" },
  { id: 22, value: "Massachusetts", label: "Massachusetts", timeZone: "America/New_York" },
  { id: 23, value: "Michigan", label: "Michigan", timeZone: "America/New_York" },
  { id: 24, value: "Minnesota", label: "Minnesota", timeZone: "America/Chicago" },
  { id: 25, value: "Mississippi", label: "Mississippi", timeZone: "America/Chicago" },
  { id: 26, value: "Missouri", label: "Missouri", timeZone: "America/Chicago" },
  { id: 27, value: "Montana", label: "Montana", timeZone: "America/Denver" },
  { id: 28, value: "Nebraska", label: "Nebraska", timeZone: "America/Chicago" },
  { id: 29, value: "Nevada", label: "Nevada", timeZone: "America/Los_Angeles" },
  { id: 30, value: "New Hampshire", label: "New Hampshire", timeZone: "America/New_York" },
  { id: 31, value: "New Jersey", label: "New Jersey", timeZone: "America/New_York" },
  { id: 32, value: "New Mexico", label: "New Mexico", timeZone: "America/Denver" },
  { id: 33, value: "New York", label: "New York", timeZone: "America/New_York" },
  { id: 34, value: "North Carolina", label: "North Carolina", timeZone: "America/New_York" },
  { id: 35, value: "North Dakota", label: "North Dakota", timeZone: "America/Chicago" },
  { id: 36, value: "Ohio", label: "Ohio", timeZone: "America/New_York" },
  { id: 37, value: "Oklahoma", label: "Oklahoma", timeZone: "America/Chicago" },
  { id: 38, value: "Oregon", label: "Oregon", timeZone: "America/Los_Angeles" },
  { id: 39, value: "Pennsylvania", label: "Pennsylvania", timeZone: "America/New_York" },
  { id: 41, value: "Rhode Island", label: "Rhode Island", timeZone: "America/New_York" },
  { id: 42, value: "South Carolina", label: "South Carolina", timeZone: "America/New_York" },
  { id: 43, value: "South Dakota", label: "South Dakota", timeZone: "America/Chicago" },
  { id: 44, value: "Tennessee", label: "Tennessee", timeZone: "America/Chicago" },
  { id: 45, value: "Texas", label: "Texas", timeZone: "America/Chicago" },
  { id: 46, value: "Utah", label: "Utah", timeZone: "America/Denver" },
  { id: 47, value: "Vermont", label: "Vermont", timeZone: "America/New_York" },
  { id: 49, value: "Virginia", label: "Virginia", timeZone: "America/New_York" },
  { id: 50, value: "Washington", label: "Washington", timeZone: "America/Los_Angeles" },
  { id: 51, value: "West Virginia", label: "West Virginia", timeZone: "America/New_York" },
  { id: 52, value: "Wisconsin", label: "Wisconsin", timeZone: "America/Chicago" },
  { id: 53, value: "Wyoming", label: "Wyoming", timeZone: "America/Denver" }
];