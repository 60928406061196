import React, { useEffect, useState, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatePicker({ selectedDate, fetchData, setSelectedDate, dates }) {
  const [highlightedDates, setHighlightedDates] = useState([]);

  const formattedDates = useMemo(() => {
    return dates?.map((dateString) => new Date(dateString));
  }, [dates]);

  useEffect(() => {
    if (!selectedDate && dates?.length > 0) {
      setSelectedDate(dates[0]);
    }
  }, [dates, selectedDate]);

  useEffect(() => {
    if (formattedDates && formattedDates.length > 0) {
      const startDate = formattedDates[0];
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);

      const newHighlightedDates = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        newHighlightedDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setHighlightedDates(newHighlightedDates);
    }
  }, [formattedDates]);

  const formatDate = useCallback((date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  const handleClick = useCallback(
    (date) => {
      const formattedDate = formatDate(date);
      const formattedDatesList = dates.map(formatDate);

      if (!formattedDatesList.includes(formattedDate)) {
        fetchData(formattedDate);
      }
      setSelectedDate(date);
    },
    [formatDate, dates, fetchData, setSelectedDate]
  );

  return (
    <>
      <style>
        {`
          .react-datepicker__header {
              background-color: transparent !important;
              border: none !important;
          }
          .react-datepicker__current-month {
              margin-top: 40px;
          }
          .react-datepicker__month {
              margin-top: 2rem;
          }
          .react-datepicker__navigation {
              top: 17px;
              padding: 30px;
          }
          .react-datepicker__day-names {
              margin-bottom: -26px;
          }
          .react-datepicker {
              font-family: 'Inter', sans-serif;
              border: none !important;
          }
          .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
              width: 2.7rem;
              line-height: 2.7rem;
              color: #4A5568;
          }
          .react-datepicker__day--highlighted.highlighted-day-circle {
              border-radius: 50%;
              background-color: transparent !important;
              position: relative;
          }
          .react-datepicker__day--highlighted.highlighted-day-circle::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 6px;
              height: 6px;
              background-color: #4A5568;
              border-radius: 50%;
              transform: translate(-50%, -50%);
          }
          .react-datepicker__day--selected {
              border-radius: 50%;
              background-color: #00c19c !important;
              color: white !important;
          }
          .react-datepicker__day--hover,
          .react-datepicker__day:hover {
              background-color: #e2e8f0 !important;
              color: #2d3748 !important;
          }
          .react-datepicker__day--included {
              background-color: #4A5568;
              border-radius: 50%;
              position: relative;
          }
          .react-datepicker__day--highlighted {
              background-color: #f0f0f0;
              color: #333;
              position: relative;
              border-radius: 50%;
          }
        `}
      </style>
      <DatePicker
        selected={selectedDate ? selectedDate : dates?.[0]}
        onChange={handleClick}
        dateFormat="MMMM d, yyyy"
        inline
        highlightDates={highlightedDates}
        includeDates={highlightedDates}
      />
    </>
  );
}

export default CustomDatePicker;
