import React, { useEffect, useState, useCallback } from "react";
import { parseISO, isEqual, format } from "date-fns";

const TimeSlots = ({
  alreadyConfirmedList,
  errMSG,
  setERRMSG,
  setSlotError,
  slotError,
  selectedTime,
  setSelectedTime,
  slots,
  selectedDate
}) => {
  const [filteredSlots, setFilteredSlots] = useState([]);

  useEffect(() => {
    if (selectedDate && slots?.length > 0) {
      const newFilteredSlots = slots?.filter((slot) => {
        return isEqual(
          format(slot?.appointmentDate, "MM/dd/yyyy"),
          format(selectedDate, "MM/dd/yyyy")
        );
      });

      setFilteredSlots(newFilteredSlots);
    }
  }, [selectedDate, slots]);

  const handleSelect = useCallback(
    (slot) => {
      if (checkForConfirmedSlots(slot, alreadyConfirmedList)) {
        setERRMSG("Appointment already exists please select another slot");
        setSlotError(true);
      } else {
        setERRMSG("");

        console.log("slot", slot);
        setSelectedTime(slot);
        setSlotError(false);
      }
    },
    [setERRMSG, setSelectedTime, setSlotError]
  );

  function checkForConfirmedSlots(slot) {
    for (let confirmed of alreadyConfirmedList) {
      if (
        slot.start_time === confirmed.start_time &&
        slot.providerId === confirmed.providerId
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-center font-semibold text-xl mb-4">Select Time</h2>
      <div className="text-center">{selectedTime?.appointmentStart}</div>
      <div
        className={`relative rounded-lg ${
          slotError ? "border-red-500 border-4" : ""
        }`}
        style={{ height: "250px" }}
      >
        <div className="grid sm:grid-cols-1 gap-2 p-2 h-80 md:h-60 overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-[#00c19c]">
          {filteredSlots.map((slot, index) => (
            <button
              key={index}
              onTouchStart={() => handleSelect(slot)}
              onClick={() => handleSelect(slot)}
              className={`p-3 h-12 text-center ${
                checkForConfirmedSlots(slot)
                  ? "bg-gray-200 cursor-not-allowed line-through "
                  : selectedTime?.start_time === slot?.start_time
                  ? "bg-[#00c19c]"
                  : "bg-gray-200 hover:bg-[#bfe2dc]"
              } rounded-lg  transition-colors duration-300 ease-in-out`}
            >
              {slot.appointmentStart.split(",")[1]} -{" "}
              {slot.appointmentEnd.split(",")[1]}
            </button>
          ))}
        </div>
      </div>
      {errMSG && (
        <div className="text-center text-lg text-red-600">{errMSG}</div>
      )}
    </div>
  );
};

export default TimeSlots;
