import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";

function State() {
  //   let selectedop = localStorage.getItem("selectedOption");
  const [address, setAddress] = useState();
  const { selectedState } = useStoreState((state) => state.appointment);
  const [currentTime, setCurrentTime] = useState("");



  useEffect(() => {
    setAddress(selectedState?.timeZone + " " + currentTime);
  }, [selectedState, currentTime]);

  useEffect(() => {
    const timer =
      selectedState &&
      setInterval(() => {
        const newDate = new Date();
        const timeFormatter = new Intl.DateTimeFormat("en-US", {
          timeZone: selectedState.timeZone,
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true
        });
        setCurrentTime(timeFormatter.format(newDate));
      }, 1000);
    return () => timer && clearInterval(timer);
  }, [selectedState]);

  return (
    <div className="text-center text-lg font-medium py-4 px-20 flex items-center justify-center space-x-2">
      <span className="hidden md:block">
        <CiLocationOn />
      </span>
      <span>{address}</span>
    </div>
  );
}

export default State;
