import { createStore, action, useStoreState } from "easy-peasy";

// Function to load existing state from localStorage
const loadPersistedState = () => {
  const persistedData = localStorage.getItem("appointment");
  return persistedData ? JSON.parse(persistedData) : {};
};

// Define your store model
const storeModel = {
  appointment: {
    selectedState: JSON.parse(localStorage.getItem("selectedOption")) || null,
    selectedDate: "",
    type: "",
    selectedTime: {},
    availableSlots: [],
    groupedData: {},
    confirmed: false,
    UID: "",
    appointmentID: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: localStorage.getItem("email") || "",
    token: "",
    data: {},
    dates: [],
    alreadyConfirmedList: [],
    loading: false,
    error: "",
    errMSG: "",

    setSelectedState: action((state, payload) => {
      state.selectedState = payload;
    }),

    setType: action((state, payload) => {
      state.type = payload;
    }),

    setFirstName: action((state, payload) => {
      state.first_name = payload;
    }),

    setLastName: action((state, payload) => {
      state.last_name = payload;
    }),

    setPhone: action((state, payload) => {
      state.phone = payload;
    }),

    setEmail: action((state, payload) => {
      state.email = payload;
    }),

    setToken: action((state, payload) => {
      state.token = payload;
    }),

    setSelectedDate: action((state, payload) => {
      state.selectedDate = payload;
    }),

    setSelectedTime: action((state, payload) => {
      state.selectedTime = payload;
    }),

    setAvailableSlots: action((state, payload) => {
      state.availableSlots = payload;
    }),

    setGroupedData: action((state, payload) => {
      state.groupedData = payload;
    }),

    setConfirmed: action((state, payload) => {
      state.confirmed = payload;
    }),

    setUID: action((state, payload) => {
      state.UID = payload;
    }),

    setAppointmentID: action((state, payload) => {
      state.appointmentID = payload;
    }),

    setData: action((state, payload) => {
      state.data = payload;
    }),

    setDates: action((state, payload) => {
      state.dates = payload;
    }),

    setLoading: action((state, payload) => {
      state.loading = payload;
    }),

    setSlotError: action((state, payload) => {
      state.slotError = payload;
    }),

    setLoadingText: action((state, payload) => {
      state.loadingText = payload;
    }),

    setAlreadyConfirmedList: action((state, payload) => {
      state.alreadyConfirmedList = payload;
    }),

    setError: action((state, payload) => {
      state.error = payload;
    }),

    setERRMSG: action((state, payload) => {
      state.errMSG = payload;
    }),

    // Action to clear all data
    clearData: action((state) => {
      Object.keys(state).forEach((key) => {
        state[key] = Array.isArray(state[key])
          ? []
          : key === "email"
          ? ""
          : null;
      });
      localStorage.removeItem("appointment"); // Clear persisted data on logout
    })
  }
};

// Load persisted state on store creation
const persistedState = loadPersistedState();

const store = createStore({
  ...storeModel,
  appointment: {
    ...storeModel.appointment,
    ...persistedState
  }
});

export default store;
