import React from "react";
import { ThreeDots } from "react-loader-spinner";

function Loader({ loadingText }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70 z-50">
      <style>
        {`
          @keyframes bubble {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px);
            }
          }
          .bubbling-text span {
            display: inline-block;
            animation: bubble 1.5s ease-in-out infinite;
          }
        `}
      </style>
      <div className="p-6 bg-white rounded-lg shadow-lg flex flex-col items-center">
        <p className="text-[#008a73] mt-3 text-center text-xl bubbling-text">
          {loadingText}
        </p>
        <ThreeDots color="#008a73" height={80} width={80} />
      </div>
    </div>
  );
}

export default Loader;