import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import State from "../components/common/state";

const ConfirmPage = () => {
  const selectedTime = useStoreState((state) => state.appointment.selectedTime);
  const navigate = useNavigate();
  let isScheduled = localStorage.getItem("isScheduled") || "0";

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();

      navigate("/confirm");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      localStorage.removeItem("token");
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <Header />
      <div className="flex flex-col min-h-screen justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
          <div className="max-w-4xl w-full rounded-lg flex justify-around sm:space-x-4">
            <div className="flex flex-col justify-start items-start p-8">
              <div className="text-2xl text-start font-bold text-black mb-4">
                Your Appointment has been booked at{" "}
                {selectedTime?.appointmentStart}
              </div>
              <ul className="list-none w-full max-w-md text-lg">
                <li className="flex items-center text-[#00c19c] mb-2">
                  <FaCheckCircle className="mr-2" />
                  Step 1: Select Date
                </li>
                <li className="flex items-center text-[#00c19c] mb-2">
                  <FaCheckCircle className="mr-2" />
                  Step 2: Choose Time Slot
                </li>
                <li className="flex items-center text-[#00c19c] mb-2">
                  <FaCheckCircle className="mr-2" />
                  Step 3: Confirm Details
                </li>
                <li className="flex items-center text-[#00c19c] mb-2">
                  <FaCheckCircle className="mr-2" />
                  Step 4: Appointment Confirmed
                </li>
              </ul>
              <div className="mt-6 w-full max-w-md">
                <h3 className="text-xl font-semibold mb-2">
                  How can we help? Our team is standing by to assist you with
                  any questions:
                </h3>
                <p className="text-gray-800 mb-1">
                  <strong>Email:</strong> info@liferxtelemd.com
                </p>
                <p className="text-gray-800">
                  <strong>Phone:</strong> (856) 212-0045
                </p>
              </div>
            </div>
          </div>
          {/* <State /> */}
        </div>
      </div>
      <Footer
        display={isScheduled === "1" || isScheduled === 1 ? false : true}
      />
    </>
  );
};

export default ConfirmPage;
