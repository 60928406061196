import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import store from "./store/store";
import Appointment from "./pages/Appointment";
import NotFound from "./404";
import SelectStatePage from "./pages/selectStatePage";
import SelectDatePage from "./pages/selectDatePage";
import ConfirmPage from "./pages/confirm";
import ProtectedRoute from "./components/routes/ProtectedRoute";

function App() {
  return (
    <StoreProvider store={store}>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Routes>
            <Route path="/90days" element={<Appointment />} />
            <Route path="/appointment" element={<SelectStatePage />} />
            <Route
              path="/appointment/select-date"
              element={<ProtectedRoute element={SelectDatePage} />}
            />
            <Route
              path="/appointment/confirm"
              element={<ProtectedRoute element={ConfirmPage} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;
