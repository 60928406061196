import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

const Help = ({ display = true }) => {
  let isScheduled = localStorage.getItem("isScheduled") || "0";
  if (!display) {
    return;
  }
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-center h-15">
        <div className=" w-full max-w-md text-center">
          <h3 className="text-xl font-semibold mb-2">
            {isScheduled === 1 || isScheduled === "1"
              ? "Need help scheduling?"
              : "How can we help you?"}
          </h3>
        </div>
        <div className="p-4 flex flex-col md:flex-row  space-y-4 md:space-y-0 md:space-x-4 ">
          <a
            href={
              isScheduled === 1 || isScheduled === "1"
                ? "mailto:hello@signup.liferxmd.net"
                : "mailto:info@liferxtelemd.com"
            }
            className="flex items-center justify-center w-68 text-center bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            aria-label="Email us"
          >
            <IoMail />
            <span className="pl-3">
              {isScheduled === 1 || isScheduled === "1"
                ? "hello@signup.liferxmd.net"
                : "info@liferxtelemd.com"}
            </span>
          </a>
          <a
            href={
              isScheduled === 1 || isScheduled === "1"
                ? "tel:+18333301873"
                : "tel:+18562120045"
            }
            className=" w-64 text-center  flex items-center justify-center bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            aria-label="Call us"
          >
            <FaPhoneAlt />{" "}
            <span className="px-3">
              {isScheduled === 1 || isScheduled === "1"
                ? "833-330-1873"
                : " 856-212-0045"}
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Help;
