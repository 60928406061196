import React, { useEffect, useCallback, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import CustomDatePicker from "../components/datePicker/DatePicker";
import { useNavigate } from "react-router-dom";
import TimeSlots from "../TimeSlots";
import { url } from "../constants/url";
import { IoIosArrowBack } from "react-icons/io";
import {
  filterNonOverlappingSlots,
  groupByStartTime,
  getUniqueAppointmentDates,
  checkTimeOverlap
} from "../utils/utils";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Loader from "../components/Loader";
import { postRequest, rescheduleAppointment } from "../components/api/api";
import State from "../components/common/state";

const SelectDatePage = () => {
  const [noSlot, setNoSlot] = useState(false);

  const appointment = useStoreState((state) => state.appointment);

  const {
    selectedState,
    errMSG,
    selectedDate,
    selectedTime,
    availableSlots,
    groupedData,
    loading,
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    token,
    UID,
    data,
    dates,
    phoneNumber,
    alreadyConfirmedList,
    loadingText
  } = appointment;

  const {
    setSelectedDate,
    setSelectedTime,
    setAvailableSlots,
    setGroupedData,
    setLoading,
    setSlotError,
    setLoadingText,
    setAlreadyConfirmedList,
    setAppointmentID,
    setToken,
    setData,
    setError,
    setERRMSG,
    setUID,
    setTime,
    setAlreadyConfirmedListAction,
    setDates
  } = useStoreActions((actions) => actions.appointment);

  const navigate = useNavigate();

  let last_name = localStorage.getItem("last_name");
  let first_name = localStorage.getItem("first_name");
  let state = localStorage.getItem("state");

  const handleConfirmBooking = async () => {
    if (selectedTime?.start_time) {
      setLoading(true);
      setSlotError(false);
      setLoadingText("Booking your appointment");

      const group = groupedData[selectedTime?.start_time] || [];
      const list = group.map((item) => item.provider_id);
      let type = localStorage.getItem("type");
      const token = localStorage.getItem("token");
      let isScheduled = localStorage.getItem("isScheduled") || "";

      const body = {
        visit_type: parseInt(type),
        reason: "",
        location_id: 3,
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        provider_id: list,
        new: isScheduled === 1 || isScheduled === "1" ? true : false,

        patient_id: UID,
        start_time: selectedTime?.start_time
      };

      try {
        const result = await rescheduleAppointment(body, token);
        if (result === 200) {
          navigate("/appointment/confirm");
        }
        if (result === 400) {
          navigate("/404");
        }
      } catch (error) {
        if (error.message === "Page not found") {
          navigate("/404");
        } else if (error.message === "Appointment already booked") {
          setAlreadyConfirmedList([
            ...alreadyConfirmedList,
            { ...selectedTime }
          ]);
          setERRMSG(
            "This Appointment is already Booked. Please select another slot."
          );
          setSlotError(true);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setERRMSG("Please select a slot to proceed.");
      setSlotError(true);
    }
  };

  const fetchData = async (date = null) => {
    setLoading(true);
    setSlotError(false);
    setLoadingText(
      "Finding Available Slots and a licensed practitioner in your state"
    );
    let type = localStorage.getItem("type");
    let isScheduled = localStorage.getItem("isScheduled") || "";

    const requestBody = {
      visit_type: parseInt(type),
      token: token,
      first_name: first_name,
      last_name: last_name,
      phone_number: phoneNumber,
      email,
      new: isScheduled === 1 || isScheduled === "1" ? true : false,
      state: state
    };

    if (date) {
      requestBody.date = date;
    }

    try {
      const result = await postRequest(
        `${url}api/v1/prognocis/get_free_slots_rescheduling/`,
        localStorage.getItem("token"),
        requestBody
      );

      if (result?.slots.length === 0) {
        setNoSlot(true);
      }

      setData(result);
    } catch (error) {
      if (error.message.includes("400")) {
        navigate("/404");
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleNoSlots = () => {
      setAvailableSlots([]);
      setDates();
      setNoSlot(true);
      setGroupedData();
      setUID();
    };

    const handleEmptySlots = () => {
      setSelectedDate();
      setDates();
      setAvailableSlots([]);
      setAlreadyConfirmedList([]);
      setGroupedData();
    };

    const handleAvailableSlots = () => {
      const retrievedOption = JSON.parse(
        localStorage.getItem("selectedOption")
      );

      setNoSlot(false);
      const filteredSlots = filterNonOverlappingSlots(
        data.slots,
        retrievedOption?.timeZone || selectedState.timeZone
      );
      const updatedSlots = [...availableSlots, ...filteredSlots];
      setAvailableSlots(updatedSlots);
      setDates(
        getUniqueAppointmentDates(
          updatedSlots,
          retrievedOption?.timeZone || selectedState.timeZone
        )
      );
      setGroupedData(groupByStartTime(data.slots));
      setUID(data.UID);
    };

    if (data?.slots) {
      if (
        data.slots.length === 0 &&
        availableSlots?.length > 0 &&
        dates.length === 0
      ) {
        handleNoSlots();
      }
      if (data.slots.length === 0 && availableSlots?.length === 0) {
        handleEmptySlots();
      }
      if (
        data.slots.length > 0 &&
        checkTimeOverlap(data.slots, availableSlots)
      ) {
        handleAvailableSlots();
      }
    }
  }, [data, selectedState]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) return <Loader loadingText={loadingText} />;

  return (
    <>
      <Header userName={firstName}></Header>
      {noSlot && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-70 bg-gray-700   z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-700">
              No Slots Found Please Select another date
            </h2>
            <button
              onClick={() => setNoSlot(false)}
              className="mt-4 w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl transition-colors duration-300 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-col min-h-screen justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
          <div className="flex justify-start mb-4">
            <button
              onClick={handleBack}
              className="bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out flex items-center"
            >
              <IoIosArrowBack className="mr-2" />
              Back
            </button>
          </div>
          <h2 className="text-2xl font-semibold text-center">
            Select a time to meet with a licensed provider
          </h2>

          <div className="grid lg:grid-cols-2 mt-4">
            <div className="flex flex-col items-center justify-center pb-14">
              <CustomDatePicker
                fetchData={fetchData}
                dates={dates}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                timeZone={selectedState?.timeZone}
              />
            </div>
            <div className="flex flex-col space-y-4 px-4 md:py-20">
              <TimeSlots
                errMSG={errMSG}
                alreadyConfirmedList={alreadyConfirmedList}
                slots={availableSlots}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                setERRMSG={setERRMSG}
                setSlotError={setSlotError}
              />
              <button
                onClick={handleConfirmBooking}
                className="w-full bg-[#00c19c] hover:bg-[#008a73] text-white font-bold py-2 rounded-3xl shadow-lg"
              >
                Confirm Booking
              </button>
            </div>
          </div>
          <State />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SelectDatePage;
