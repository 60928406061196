import React from "react";
import Help from "../common/help";

function Footer() {
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-center">
        <Help />
      </div>
      <footer className="bg-[#00c19c] text-black p-4 text-center mt-auto">
        <p>
          © 2024 <a href="https://liferx.md/">LifeRx</a> Appointment Booking.
          All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default Footer;
