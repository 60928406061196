

import { url } from "../../constants/url";

export const rescheduleAppointment = async (body, token) => {
  try {
    const response = await fetch(
      `${url}api/v1/prognocis/reschedule_appointment/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Prognocis-Token": token
        },
        body: JSON.stringify(body)
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error("Page not found");
      }
      if (response.status === 403) {
        throw new Error("Appointment already booked");
      }
      throw new Error("Unknown error");
    }
    return response.status;
  } catch (error) {
    throw new Error(error.message);
  }
};

// src/utils/apiUtils.js
export const postRequest = async (url, token, requestBody) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Prognocis-Token": token
    },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};



