import React, { useEffect, useState } from "react";
import LOGO from "../../LogoNew.png";
import { useStoreState } from "easy-peasy";
import { types } from "../../constants/types";

function Header({ userName = "NA", userImage, header = false, setSteps }) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [showSmallHeader, setShowSmallHeader] = useState(false);
  const firstName = useStoreState((state) => state.appointment.first_name);
  const type = useStoreState((state) => state.appointment.type);
  let localName = localStorage.getItem("name") || "";

  useEffect(() => {
    let timerId;
    if (header) {
      setTimeRemaining(30 * 60);
      timerId = setInterval(() => {
        setTimeRemaining((prevTimeRemaining) => {
          if (prevTimeRemaining <= 0) {
            clearInterval(timerId);
            setSteps(4);
            return 0;
          }
          return prevTimeRemaining - 1;
        });
      }, 1000);

      setTimeout(() => {
        setShowSmallHeader(true);
      }, 500);
    } else {
      setShowSmallHeader(false);
      setTimeRemaining(0);
    }

    return () => clearInterval(timerId);
  }, [header, setSteps]);

  const getLabelByValue = (value) => {
    const foundType = types.find((type) => type.value === value);
    return foundType ? foundType.label : "90 Day Follow up";
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="relative">
      <div className="bg-white h-20 container mx-auto text-gray-800 px-4 sm:px-5 flex justify-between items-center">
        <img src={LOGO} alt="Logo" className="h-16 w-auto" />
        <div
          title="Appointment Type"
          className="hidden md:block text-xl sm:text-2xl md:text-4xl text-gray-800 font-bold truncate max-w-[40%]"
        >
          {getLabelByValue(parseInt(type))}
        </div>

        <div className="flex items-center space-x-3">
          <h1 className="text-lg capitalize font-bold truncate max-w-[100px] sm:max-w-none">
            {localName || firstName || userName || ""}
          </h1>
          {userImage ? (
            <img
              src={userImage}
              alt="User"
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-full"
            />
          ) : (
            <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-[#00c19c] flex items-center justify-center">
              <span className="text-lg sm:text-xl font-semibold capitalize text-white">
                {"" || localName[0] || firstName[0] || userName[0]}
              </span>
            </div>
          )}
        </div>
      </div>

      {showSmallHeader && (
        <div
          className={`${
            showSmallHeader ? "slide-down" : "hidden"
          } bg-[#bfe2dc] w-full text-center py-2 left-0`}
          style={{ top: "5rem" }}
        >
          <p className="text-lg font-semibold">
            Appointment is reserved for: {formatTime(timeRemaining)}
          </p>
        </div>
      )}
    </div>
  );
}

export default Header;
